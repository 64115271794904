import React, { useEffect, useState } from "react";
import Logo from "../assets/img/logo/logo.svg";
import iheb from "../assets/img/team/Iheb.jpeg";

import ThanksImage from "../assets/img/thanks.jpg";
import GraphIcon from "../assets/img/animated-icon/graph.png";
import StarIcon from "../assets/img/animated-icon/star-icon.png";
import StarImage from "../assets/img/animated-icon/star.png";
import TeamImage from "../assets/img/hero/team.jpg";
import Customer1 from "../assets/img/hero/customer-1.jpg";
import Customer2 from "../assets/img/hero/customer-2.jpg";
import ComputerGesture from "../assets/img/hero/customer-service-cute-guy-grey-suit-with-computer-headset-smiling-showing-good-gesture.jpg";
import ComputerWhileC from "../assets/img/hero/happy-business-couple-working-computer-while-sitting-cafe.jpg";

import modernEquippedComputerLab from "../assets/img/hero/modern-equipped-computer-lab.jpg";
import graphIcon from "../assets/img/icon/graph.svg";
import softwareIcon from "../assets/img/icon/software.svg";
import productIcon from "../assets/img/icon/product.svg";
import uiIcon from "../assets/img/icon/ui.svg";
import brandIcon from "../assets/img/icon/brand.svg";
import marketingIcon from "../assets/img/icon/marketing.svg";
import graphicIcon from "../assets/img/icon/graphic.svg";
import uiuxIcon from "../assets/img/icon/uiux.svg";
import designTeam from "../assets/img/about/design-team.jpg";
import researchTeam from "../assets/img/about/reasearch-team.jpg";
import marketingTeam from "../assets/img/about/marketing-team.jpg";
import developerTeam from "../assets/img/about/developer-team.jpg";

import eLearningImage from "../assets/img/project/e-learning.jpg";
import futureDesignImage from "../assets/img/project/future-design.jpg";
import dashboardDesignImage from "../assets/img/project/dasboard-design.jpg";
import ecommerceMobileImage from "../assets/img/project/eccomerce-mobile.jpg";
import sassLandingImage from "../assets/img/project/sass-landing.jpg";

import team1 from "../assets/img/team/team-1.png";
import team3 from "../assets/img/team/team-3.png";
import team4 from "../assets/img/team/team-4.png";
import teamMember4 from "../assets/img/team/team-member-4.png";
import testimonialTwo from "../assets/img/testimonial/testimonial-two.jpg";
import quoteTwo from "../assets/img/animated-icon/quote-two.png";
import author1 from "../assets/img/testimonial/author-1.png";
import author2 from "../assets/img/testimonial/author-2.png";
import author3 from "../assets/img/testimonial/author-3.png";
import blog1 from "../assets/img/blog/blog-1.jpg";
import blog1_2 from "../assets/img/blog/blog-1-2.jpg";
import blog1_3 from "../assets/img/blog/blog-1-3.jpg";
import author1Img from "../assets/img/author/author-1.jpg";
import author2Img from "../assets/img/author/author-2.jpg";
import author3Img from "../assets/img/author/author-3.jpg";
import footerLogo from "../assets/img/logo/footer-logo.svg";
import sponsor1 from "../assets/img/clients/sponsor-1.png";
import sponsor2 from "../assets/img/clients/sponsor-2.png";
import sponsor3 from "../assets/img/clients/sponsor-3.png";
import sponsor4 from "../assets/img/clients/sponsor-4.png";
import sponsor5 from "../assets/img/clients/sponsor-5.png";
import line3 from "../assets/img/animated-icon/line-3.png";
import about3 from "../assets/img/about/about-3.png";
import about31 from "../assets/img/about/about-3-1.png";
import down1 from "../assets/img/down-1.png";
import down2 from "../assets/img/down-2.png";
import ammarSign from "../assets/img/signature 1.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { isEmpty } from "lodash";
import ReactSelect from "react-select";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
} from "reactstrap";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const [fullName, setfullName] = useState([]);
  const [skype, setskype] = useState([]);
  const [phone, setphone] = useState([]);
  const [email, setemail] = useState([]);
  const [message, setmessage] = useState([]);
  const [modalopen, setmodalopen] = useState(false);
  const [item, setitem] = useState({});
  const [lang, setlang] = useState({ value: "EN", label: "EN" });

  const [menu, setmenu] = useState(false);
  document.documentElement.dir = lang.value === "AR" && "rtl"; // for rtl

  useEffect(() => {
    if (
      localStorage.getItem("lang") &&
      JSON.parse(localStorage.getItem("lang"))
    ) {
      setlang(JSON.parse(localStorage.getItem("lang")));
    }
    axios
      .get("https://www.hmatechs.com/api/statistic/AllData")
      .then((response) => {
        if (response?.data) {
          setData(response?.data);
        }
      });
  }, []);

  const addContact = async () => {
    await axios
      .post("https://www.hmatechs.com/api/contact", {
        fullName,
        skype,
        phone,
        email,
        message,
      })
      .then((response) => {})
      .catch(function (err) {});
  };

  const handelOpenModal = (e) => {
    setmodalopen(true);
    setitem(e);
  };

  return (
    <>
      <header className="header">
        <div className="container">
          <nav>
            <a href="#homeid">
              <div class="logo">
                {" "}
                <img src={Logo} alt="Insoand Logo" />
              </div>
            </a>

            <input
              type="checkbox"
              id="checkbox"
              checked={menu}
              onChange={(e) => setmenu(e.target.checked)}
            />
            <label for="checkbox" id="icon">
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
            <ul>
              <li>
                <a href="#homeid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `Home`
                    : lang.value === "FR"
                    ? `Accueil`
                    : `الصفحة الرئيسية`}
                </a>
              </li>
              <li>
                <a href="#aboutid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `About`
                    : lang.value === "FR"
                    ? `À propos de nous`
                    : `معلومات عنا`}
                </a>
              </li>
              <li>
                <a href="#projectsid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `Portfolio`
                    : lang.value === "FR"
                    ? `Portefeuille`
                    : `المشاريع`}
                </a>
              </li>
              <li>
                <a href="#Servicesid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `Services`
                    : lang.value === "FR"
                    ? `Services`
                    : `خدمات`}
                </a>
              </li>
              {/* <li>
                <a href="#teamid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `Team`
                    : lang.value === "FR"
                    ? `Équipe`
                    : `فريق`}
                </a>
              </li> */}
              <li>
                <a href="#contactid" onClick={(e) => setmenu(false)}>
                  {lang.value === "EN"
                    ? `Contact`
                    : lang.value === "FR"
                    ? `Contact`
                    : `اتصال`}
                </a>
              </li>

              <li>
                <a
                  href="#"
                  onClick={(e) => {
                    navigate("/events");
                    window.location.reload();
                    setmenu(false);
                  }}
                >
                  {lang.value === "EN"
                    ? `HMA Events`
                    : lang.value === "FR"
                    ? `Événements HMA`
                    : `فعاليات HMA`}
                </a>
              </li>
            </ul>
            <ReactSelect
              className="selectLang"
              isSearchable={false}
              value={lang}
              onChange={(val) => {
                setlang(val);
                localStorage.setItem("lang", JSON.stringify(val));
              }}
              theme={(theme) => ({
                ...theme,
                //borderRadius: 0,
                borderStyle: "none",
                boxShadow: "none",
                colors: {
                  ...theme.colors,
                  /*
                   * control/backgroundColor
                   * menu/backgroundColor
                   * option/color(selected)
                   */
                  neutral0: "#0d0c1c",
                  neutral30: "#0d0c1c", //control/borderColor(focused)
                  neutral10: "#fff",
                  neutral50: "#000", // pacleholder color
                  neutral80: "#fff", //input color
                  primary25: "#c1c1c1", //option bg color focued
                  primary: "#238aec", //option bg color selected
                  primary50: "#238aec", // option bg color active(enavled or available)
                },
              })}
              styles={{
                control: (base) => ({
                  ...base,
                  border: 0,
                  // This line disable the blue border
                  boxShadow: "none",
                }),

                option: (base) => ({
                  ...base,
                  color: "#fff",
                }),
              }}
              options={[
                { value: "EN", label: "EN" },
                { value: "FR", label: "FR" },
                { value: "AR", label: "AR" },
              ]}
            />
          </nav>
        </div>
      </header>
      {/* header menu end */}
      {/* hero area */}
      <div className="hero pt-100 pb-100 hero-walpaper" id="homeid">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="hero__text">
                {/* <div className="hero__vector">
                  <img src={GraphIcon} alt="graph" />
                  <img src={StarIcon} alt="graph" />
                </div> */}
                <div className="hero__text--content relative">
                  <h4>
                    {" "}
                    {lang.value === "EN"
                      ? "CREATIVITY • INNOVATION • FUTURE"
                      : lang.value === "FR"
                      ? "CRÉATIVITÉ • INNOVATION • AVENIR"
                      : "الإبداع • الابتكار • المستقبل"}{" "}
                  </h4>{" "}
                  <br />
                  {/* <img src={StarImage} alt="graph" /> */}
                  <h3>
                    {lang.value === "EN"
                      ? "LEADING THE"
                      : lang.value === "FR"
                      ? "Menant le"
                      : "قيادة"}{" "}
                    <span>
                      {" "}
                      {lang.value === "EN"
                        ? "INNOVATION"
                        : lang.value === "FR"
                        ? "INNOVATION"
                        : "ابتكار"}{" "}
                    </span>
                  </h3>
                  {/* <div className="hero__video" data-sal="slide-left" data-sal-delay={400} data-sal-easing="ease-out-back">
                    <div className="video-img">
                      <img src={TeamImage} alt="" />
                      <div className="video-play" data-video-id="u31qwQUeGuM">
                        <i className="fa-solid fa-play" />
                      </div>
                    </div>
                  </div> */}
                  <div className="w-25 description-hero">
                    <span className="text-break">
                      {lang.value === "EN"
                        ? " HMATechs is a registered and goverment-tusted IT comspanany with all required licenses and certificates"
                        : lang.value === "FR"
                        ? "HMATechs est une société informatique enregistrée et approuvée par le gouvernement avec toutes les licences et certificats requis"
                        : "HMATechs هي شركة تكنولوجيا معلومات مسجلة وموثوقة من قبل الحكومة ولديها جميع التراخيص والشهادات المطلوبة"}
                    </span>
                  </div>
                  <div className="hero__button">
                    <a href="#contactidFrom" className="rounded-btn-relative">
                      {lang.value === "EN"
                        ? " Get in Touch"
                        : lang.value === "FR"
                        ? "Entrer en contact"
                        : "تواصل معنا"}

                      {/* <span>
                      <i className="fa-sharp fa-light fa-arrow-right-long" />
                    </span> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* hero area end */}
      {/* about */}
      <div className="about style-two fix pt-110 pb-110" id="aboutid">
        <div className="container position-relative">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="about__images position-relative">
                <div className="animated__image" key="line3">
                  <img src={line3} alt="" />
                </div>
                <div className="img-with-text" key="about3">
                  <img
                    src={isEmpty(data?.About) ? about3 : data?.About?.image1}
                    alt=""
                  />
                  <div className="expeert">
                    <span>15</span>{" "}
                    {lang.value === "EN"
                      ? "Years of Experience"
                      : lang.value === "FR"
                      ? "Des années d'expérience"
                      : "سنوات من الخبرة"}
                  </div>
                </div>
                <div className="img-notext" key="about31">
                  <img
                    src={isEmpty(data?.About) ? about31 : data?.About?.image2}
                    alt=""
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <h3>
                {lang.value === "EN"
                  ? `About Us`
                  : lang.value === "FR"
                  ? ``
                  : ``}{" "}
              </h3>
              <p>
                {isEmpty(data?.About)
                  ? `HMATechs was established on January 1st, 2009, in the Hashemite Kingdom of Jordan and Tunisia, focusing on providing a one-of-kind services package in Middle East & Africa region (MEA).
                  HMATechs official work in GCC started in 2015 by providing its clients with Niche (ICT solutions, and consulting services and sale of software).
                  In 2021 mission has been expanded in Qatar to be the Hub and to harness the Emerging-Technology to Revolutionize the Innovational output for enterprises and startups in the region.
                  HMATechs today has a business partnership with tech solutions providers in the global market to help its clients in their Digital Transformational and business productivity Enhancement goals from day one.`
                  : lang.value === "EN"
                  ? data?.About?.description
                  : lang.value === "FR"
                  ? data?.About?.descriptionFR
                  : data?.About?.descriptionAR}
              </p>

              <a href="#Servicesid" className="mt-30 button__two">
                {lang.value === "EN"
                  ? "Learn More About"
                  : lang.value === "FR"
                  ? "En savoir plus sur"
                  : "تعرف على المزيد حول"}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* about us end */}

      {/* service */}
      <section className="service pt-100 pb-10" id="Servicesid">
        <div className="container">
          <div className="section">
            <div className="section__content">
              <h6 className="section__sub">
                {" "}
                {lang.value === "EN"
                  ? "Which Services We Provide"
                  : lang.value === "FR"
                  ? "Quels services nous fournissons"
                  : "ما هي الخدمات التي نقدمها"}
              </h6>
              <h3 className="section__title">
                {lang.value === "EN"
                  ? "Modern"
                  : lang.value === "FR"
                  ? "Modern"
                  : "حديث"}
                &amp;{" "}
                {lang.value === "EN"
                  ? "Intuitive web"
                  : lang.value === "FR"
                  ? "Internet intuitif"
                  : "شبكة بديهية"}{" "}
                <strong>
                  {lang.value === "EN"
                    ? "Solutions"
                    : lang.value === "FR"
                    ? "Solutions"
                    : "الحلول"}
                </strong>{" "}
                {lang.value === "EN"
                  ? "Agency"
                  : lang.value === "FR"
                  ? "Agence"
                  : "وكالة"}
              </h3>
            </div>
            {/* <div className="view__all">
              <a href="service.html">View All Services</a>
            </div> */}
          </div>
          <div className="row">
            {data?.Service?.map((e) => {
              return (
                <div className="col-lg-4 col-xl-4 col-md-4">
                  <div className="service__single">
                    <div className="service__single--box">
                      <div className="icon">
                        <img src={softwareIcon} alt="" />
                      </div>
                      <div className="service__single--box-meta">
                        <div className="meta-text">
                          <a href="#">
                            {" "}
                            {lang.value === "EN"
                              ? e?.title
                              : lang.value === "FR"
                              ? e?.titleFR
                              : e?.titleAR}{" "}
                          </a>
                          <span>
                            {lang.value === "EN"
                              ? e?.description?.slice(0, 50)
                              : lang.value === "FR"
                              ? e?.descriptionFR?.slice(0, 50)
                              : e?.descriptionAR?.slice(0, 50)}{" "}
                            ...
                          </span>
                        </div>
                        <div
                          className="meta-linkbtn"
                          onClick={() => handelOpenModal(e)}
                        >
                          <a className="link-btn">
                            <i className="fa-sharp fa-light fa-arrow-right-long" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* service end */}

      {/* work marque */}
      <div className="our-work pt-100 pb-100">
        <div className="our-work__wrapper">
          <div className="our-work__content">
            <div className="single-work-item">
              <span className="title">
                {" "}
                {lang.value === "EN"
                  ? "INNOVATION"
                  : lang.value === "FR"
                  ? "INNOVATION"
                  : "ابتكار"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "INNOVATION"
                  : lang.value === "FR"
                  ? ""
                  : ""}{" "}
              </span>
              <span className="start">*</span>
            </div>

            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "AI"
                  : lang.value === "FR"
                  ? "IA"
                  : "الذكاء الاصطناعي"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "CREATIVITY"
                  : lang.value === "FR"
                  ? "CRÉATIVITÉ"
                  : "إِبداع"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "INNOVATION"
                  : lang.value === "FR"
                  ? "INNOVATION"
                  : "ابتكار"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "Development"
                  : lang.value === "FR"
                  ? "Développement"
                  : "تطوير"}{" "}
              </span>
              <span className="start">*</span>
            </div>

            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "AI"
                  : lang.value === "FR"
                  ? "IA"
                  : "الذكاء الاصطناعي"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "CREATIVITY"
                  : lang.value === "FR"
                  ? "CRÉATIVITÉ"
                  : "إِبداع"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "INNOVATION"
                  : lang.value === "FR"
                  ? "INNOVATION"
                  : "ابتكار"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "Development"
                  : lang.value === "FR"
                  ? "Développement"
                  : "تطوير"}{" "}
              </span>
              <span className="start">*</span>
            </div>

            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "AI"
                  : lang.value === "FR"
                  ? "IA"
                  : "الذكاء الاصطناعي"}{" "}
              </span>
              <span className="start">*</span>
            </div>
            <div className="single-work-item">
              <span className="title">
                {lang.value === "EN"
                  ? "CREATIVITY"
                  : lang.value === "FR"
                  ? "CRÉATIVITÉ"
                  : "إِبداع"}
              </span>
              <span className="start">*</span>
            </div>
          </div>
        </div>
      </div>
      {/* work marque end */}
      {/* our project */}
      <section className="project" id="projectsid">
        <div className="container">
          <div className="section">
            <div className="section__content">
              <h6 className="section__sub">
                {lang.value === "EN"
                  ? "Latest Project"
                  : lang.value === "FR"
                  ? "Dernier projet"
                  : "أحدث مشروع"}{" "}
              </h6>
              <h3 className="section__title">
                {lang.value === "EN"
                  ? "Our Latest Awards Winning "
                  : lang.value === "FR"
                  ? "Nos derniers prix remportés"
                  : "أحدث جوائزنا الفائزة"}{" "}
                <strong>
                  {" "}
                  {lang.value === "EN"
                    ? "Projects "
                    : lang.value === "FR"
                    ? "Projets"
                    : "المشاريع"}{" "}
                </strong>{" "}
              </h3>
            </div>
            {/* <div className="view__all">
              <a href="portfolio.html">View All Projects</a>
            </div> */}
          </div>
          <div className="row">
            {data?.Project?.map((e) => (
              <div className="col-lg-4 col-md-6">
                <div
                  className="project__single"
                  title="E-learning Website Project"
                >
                  <div className="project__single--box">
                    <img className="img-fluid" src={e?.images[0]} alt="" />
                    <div className="project__meta">
                      <div className="project__meta--info">
                        <span className="project-name">
                          <a href="portfolio-details.html">
                            {" "}
                            {lang.value === "EN"
                              ? e?.name
                              : lang.value === "FR"
                              ? e?.nameFR
                              : e?.nameAR}
                          </a>
                        </span>
                        <span className="project-by">
                          {lang.value === "EN"
                            ? e?.client
                            : lang.value === "FR"
                            ? e?.clientFR
                            : e?.clientAR}
                        </span>
                      </div>
                      <div className="project__meta--link">
                        <a href={e?.link} className="link-btn">
                          <i className="fa-sharp fa-light fa-arrow-right-long" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      {/* our project end */}

      <div className="counter-five-area pt-100 mt-50 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="counter-five-content">
                <h3>
                  {" "}
                  {lang.value === "EN"
                    ? "We Create Websites Business Services , AI , IOT "
                    : lang.value === "FR"
                    ? "Nous créons des sites Web, des services aux entreprises, de l'IA, de l'IOT"
                    : "نحن نقوم بإنشاء مواقع الويب وخدمات الأعمال والذكاء الاصطناعي وإنترنت الأشياء"}{" "}
                </h3>
                <p>
                  {lang.value === "EN"
                    ? " Our approach is centered around understanding the unique objectives and challenges of each client."
                    : lang.value === "FR"
                    ? "Notre approche est centrée sur la compréhension des objectifs et des défis uniques de chaque client."
                    : "يتمحور نهجنا حول فهم الأهداف والتحديات الفريدة لكل عميل."}
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five color">
                    <h2>
                      12K <img src={down1} alt="down" />
                    </h2>
                    <span>
                      {" "}
                      {lang.value === "EN"
                        ? "Happy Customers"
                        : lang.value === "FR"
                        ? "Clients satisfaits"
                        : "العملاء السعداء"}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five">
                    <h2>
                      35K <img src={down2} alt="down" />
                    </h2>
                    <span>
                      {" "}
                      {lang.value === "EN"
                        ? "Success Project"
                        : lang.value === "FR"
                        ? "Projet de réussite"
                        : "مشروع النجاح"}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five ms-100">
                    <h2>
                      150% <img src={down2} alt="down" />
                    </h2>
                    <span>
                      {" "}
                      {lang.value === "EN"
                        ? "Conversion Rate Increased"
                        : lang.value === "FR"
                        ? "Taux de conversion augmenté"
                        : "زيادة معدل التحويل"}{" "}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6 col-md-3 col-sm-6">
                  <div className="counter-single-item-five ms-100">
                    <h2>
                      50+ <img src={down2} alt="down" />
                    </h2>
                    <span>
                      {" "}
                      {lang.value === "EN"
                        ? "Awards Winning"
                        : lang.value === "FR"
                        ? "Récompenses"
                        : "الحائز على جوائز"}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <section className="team pt-70 pb-70" id="teamid">
        <div className="container">
          <div className="section">
            <div className="section__content">
              <h6 className="section__sub">
                {" "}
                {lang.value === "EN"
                  ? "Our Team"
                  : lang.value === "FR"
                  ? "Notre équipe"
                  : "فريقنا"}
              </h6>
              <h3 className="section__title">
                {lang.value === "EN"
                  ? "Our Experienced"
                  : lang.value === "FR"
                  ? "Notre expérience"
                  : "خبرائنا"}{" "}
                <strong>
                  {lang.value === "EN"
                    ? "Our Team"
                    : lang.value === "FR"
                    ? "Notre équipe"
                    : "فريقنا"}
                </strong>{" "}
              </h3>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-md-6">
              <div className="team__single">
                <div className="team__single--box">
                  <div className="team__single--wrap">
                    <img className="img-fluid" src={iheb} alt="" />
                    <div className="team-meta">
                      <div className="team-meta-info">
                        <a href="#">Iheb Baccouche</a>
                        <span>Full Stack Developer</span>
                      </div>
                      <div className="team-meta-social">
                        <div className="icon">
                          <i className="fa-solid fa-plus" />
                        </div>
                        <div className="social-links">
                          <a href="https://wa.me/+21656712636" target="_blank">
                            <i className="fa-brands fa-whatsapp" />
                          </a>
                          <a
                            href="https://www.linkedin.com/in/iheb-baccouche-5b98b9200/"
                            target="_blank"
                          >
                            <i className="fa-brands fa-linkedin" />
                          </a>
                          <a href="https://github.com/Ihebbac" target="_blank">
                            <i className="fa-brands fa-github" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {data?.Teams?.length !== 0 &&
              data?.Teams?.map((el) => (
                <div className="col-xl-3 col-lg-6 col-md-6">
                  <div className="team__single">
                    <div className="team__single--box">
                      <div className="team__single--wrap">
                        <img className="img-fluid" src={el.image} alt="" />
                        <div className="team-meta">
                          <div className="team-meta-info">
                            <a href="#">
                              {lang.value === "EN"
                                ? el.fullName
                                : lang.value === "FR"
                                ? el.fullNameFR
                                : el.fullNameAR}
                            </a>
                            <span>
                              {lang.value === "EN"
                                ? el.position
                                : lang.value === "FR"
                                ? el.positionFR
                                : el.positionAR}
                            </span>
                          </div>
                          <div className="team-meta-social">
                            <div className="icon">
                              <i className="fa-solid fa-plus" />
                            </div>
                            <div className="social-links">
                              <a href={el?.whatsapp ?? "#"} target="_blank">
                                <i className="fa-brands fa-whatsapp" />
                              </a>
                              <a href={el?.linkedIn ?? "#"} target="_blank">
                                <i className="fa-brands fa-linkedin" />
                              </a>
                              <a href={el?.github ?? "#"} target="_blank">
                                <i className="fa-brands fa-github" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </section> */}

      {/* our team end */}

      {data?.Teams?.length === 0 ? (
        <div className="row align-items-center p-50 bg-light">
          {/* Image Placeholder */}

          <div className="col-md-4 mr-150">
            <div
              className="ceo-picture rounded"
              style={{ width: "100%", paddingTop: "100%" }}
            />

            <div className="d-flex justify-content-center align-items-center flex-column">
              <img className="image-ceo-sign mt-25" src={ammarSign} alt="" />

              <h2 className="fw-bold fst-italic" style={{ color: "black" }}>
                CEO
              </h2>
            </div>
          </div>

          {/* Text Content */}
          <div className="col-md-6 paragraph-ceo">
            <p className="text-justify" style={{ textAlign: "justify" }}>
              {lang.value === "EN"
                ? `Mohamed AMMAR is a visionary leader and innovator, serving as the
              Founder and CEO of a cutting-edge enterprise dedicated to
              entrepreneurship, innovation management, and driving digital
              transformation. I am passionate about helping startups and
              established companies alike navigate the complexities of cloud
              technology and digital transformation, ensuring they stay
              competitive in today’s fast-paced environment. As a consultant, I
              work closely with organizations to craft tailored strategies that
              foster growth, agility, and efficiency.`
                : lang.value === "FR"
                ? `Mohamed AMMAR est un leader visionnaire et un innovateur, fondateur et PDG d'une entreprise de pointe dédiée à l'entrepreneuriat, à la gestion de l'innovation et à la transformation numérique. Je suis passionné par l'aide aux startups et aux entreprises établies pour les aider à s'orienter dans les complexités de la technologie cloud et de la transformation numérique, en veillant à ce qu'elles restent compétitives dans l'environnement en évolution rapide d'aujourd'hui. En tant que consultant, je travaille en étroite collaboration avec les organisations pour élaborer des stratégies sur mesure qui favorisent la croissance, l'agilité et l'efficacité.`
                : `محمد عمار هو قائد ومبتكر صاحب رؤية، يعمل كمؤسس ومدير تنفيذي لمؤسسة متطورة مخصصة لريادة الأعمال وإدارة الابتكار ودفع التحول الرقمي. أنا شغوف بمساعدة الشركات الناشئة والشركات الراسخة على حد سواء في التعامل مع تعقيدات تكنولوجيا الحوسبة السحابية والتحول الرقمي، وضمان بقائها قادرة على المنافسة في بيئة اليوم سريعة الخطى. بصفتي مستشارًا، أعمل بشكل وثيق مع المؤسسات لصياغة استراتيجيات مخصصة تعزز النمو والمرونة والكفاءة.`}
              <br />
              {lang.value === "EN"
                ? `     In addition to my consulting work, I am an economist and
              sought-after speaker, frequently sharing insights on the evolving
              digital landscape, innovation strategies, and the economic impact
              of technological advancements. My mission is to empower businesses
              to harness the full potential of digital tools and practices,
              making their transformation journey not only seamless but also
              sustainable. With a strong commitment to innovation and
              forward-thinking solutions, I continue to support companies in
              their pursuit of growth and success in an ever-changing digital
              world.`
                : lang.value === "FR"
                ? `En plus de mon travail de conseil, je suis économiste et conférencier recherché, partageant fréquemment mes idées sur l'évolution du paysage numérique, les stratégies d'innovation et l'impact économique des avancées technologiques. Ma mission est de permettre aux entreprises d'exploiter tout le potentiel des outils et pratiques numériques, rendant leur parcours de transformation non seulement transparent mais également durable. Fort d'un engagement fort en faveur de l'innovation et des solutions avant-gardistes, je continue d'accompagner les entreprises dans leur quête de croissance et de réussite dans un monde numérique en constante évolution.`
                : `بالإضافة إلى عملي الاستشاري، فأنا خبير اقتصادي ومتحدث مطلوب، أشارك بشكل متكرر رؤى حول المشهد الرقمي المتطور، واستراتيجيات الابتكار، والتأثير الاقتصادي للتقدم التكنولوجي. مهمتي هي تمكين الشركات من الاستفادة من الإمكانات الكاملة للأدوات والممارسات الرقمية، مما يجعل رحلة التحول الخاصة بها سلسة ومستدامة أيضًا. مع التزام قوي بالابتكار والحلول المستقبلية، أواصل دعم الشركات في سعيها لتحقيق النمو والنجاح في عالم رقمي متغير باستمرار.`}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}

      {/* sponsor */}
      <div className="sponsor style-two">
        <div className="container">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            breakpoints={{
              500: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            pagination={{
              clickable: true,
            }}
            className="mySwiper"
          >
            {data?.Parteners?.map((e) => (
              <SwiperSlide className="swiper-slide">
                {" "}
                <div key="sponsor1">
                  <img src={e?.image} alt="" width={150} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      {/* sponsor end */}

      {/* breadcrumb */}
      <div className="breadcrumb pt-80 pb-80" id="contactid">
        <div className="container">
          <div className="breadcrumb__vector">
            <img src="assets/img/animated-icon/graph.png" alt="graph" />
            <img src="assets/img/animated-icon/star-icon.png" alt="graph" />
          </div>
          <div className="breadcrumb__wrapper">
            <div className="breadcrumb__wrapper--text">
              <h5 className="title">
                {" "}
                {lang.value === "EN"
                  ? `Contact Us`
                  : lang.value === "FR"
                  ? `Contactez-nous`
                  : `اتصل بنا`}{" "}
              </h5>
              <p>
                {lang.value === "EN"
                  ? `For businesses with digital products or services, ensuring a
                visually appealing interface is vital.`
                  : lang.value === "FR"
                  ? `Pour les entreprises proposant des produits ou des services numériques, il est essentiel de garantir une interface
visuellement attrayante.`
                  : `بالنسبة للشركات التي لديها منتجات أو خدمات رقمية، فإن ضمان وجود واجهة جذابة بصريًا أمر حيوي.`}
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* breadcrumb end */}

      {/* contact us */}
      <div className="contact pt-80 pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-solid fa-phone" />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      {lang.value === "EN"
                        ? `Phone`
                        : lang.value === "FR"
                        ? `Téléphone`
                        : `هاتف`}{" "}
                    </h6>
                    <a
                      href={
                        isEmpty(data?.Configurations)
                          ? "phone"
                          : data?.Configurations?.phone
                      }
                    >
                      {" "}
                      {isEmpty(data?.Configurations)
                        ? "phone"
                        : data?.Configurations?.phone}
                    </a>
                    <a href="mailto:contact@hma.org">
                      {" "}
                      {isEmpty(data?.Configurations)
                        ? "email"
                        : data?.Configurations?.email}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-solid fa-location-dot" />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      {" "}
                      {lang.value === "EN"
                        ? `Address`
                        : lang.value === "FR"
                        ? `Adresse`
                        : `عنوان`}{" "}
                    </h6>
                    <p>
                      {" "}
                      {isEmpty(data?.Configurations)
                        ? "address"
                        : lang.value === "EN"
                        ? data?.Configurations?.address
                        : lang.value === "FR"
                        ? data?.Configurations?.addressFR
                        : data?.Configurations?.addressAR}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="contact__single">
                <div className="contact__single--item">
                  <div className="icon">
                    <i className="fa-regular fa-clock" />
                  </div>
                  <div className="content">
                    <h6 className="title">
                      {" "}
                      {lang.value === "EN"
                        ? `Opening Hours`
                        : lang.value === "FR"
                        ? `Heures d'ouverture`
                        : `ساعات العمل`}{" "}
                    </h6>
                    <p>
                      {isEmpty(data?.Configurations)
                        ? "address"
                        : lang.value === "EN"
                        ? data?.Configurations?.openingDayStart
                        : lang.value === "FR"
                        ? data?.Configurations?.openingDayStartFR
                        : data?.Configurations?.openingDayStartAR}
                      -{" "}
                      {isEmpty(data?.Configurations)
                        ? "address"
                        : lang.value === "EN"
                        ? data?.Configurations?.openingDayEnd
                        : lang.value === "FR"
                        ? data?.Configurations?.openingDayEndFR
                        : data?.Configurations?.openingDayEndAR}
                    </p>
                    <p>
                      {isEmpty(data?.Configurations)
                        ? "address"
                        : lang.value === "EN"
                        ? data?.Configurations?.openingHourStart
                        : lang.value === "FR"
                        ? data?.Configurations?.openingHourStartFR
                        : data?.Configurations?.openingHourStartAR}
                      -{" "}
                      {isEmpty(data?.Configurations)
                        ? "address"
                        : lang.value === "EN"
                        ? data?.Configurations?.openingHourEnd
                        : lang.value === "FR"
                        ? data?.Configurations?.openingHourEndFR
                        : data?.Configurations?.openingHourEndAR}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="contact__form" id="contactidFrom">
              <div className="comment__template contact__template">
                <div className="comment__template--box input__form">
                  <form
                    // action="mail-sender.php"
                    // method="post"
                    className="contact__php"
                  >
                    <div className="input-group">
                      <div className="single-input">
                        <label htmlFor="name">
                          {lang.value === "EN"
                            ? `Full Name`
                            : lang.value === "FR"
                            ? `Nom et prénom`
                            : `الاسم الكامل`}{" "}
                        </label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          placeholder="Your Name"
                          required=""
                          onChange={(e) => {
                            setfullName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="email">
                          {" "}
                          {lang.value === "EN"
                            ? `Email Address`
                            : lang.value === "FR"
                            ? `Adresse email`
                            : `عنوان البريد الإلكتروني`}{" "}
                        </label>
                        <input
                          type="text"
                          id="email"
                          name="email"
                          placeholder="Your email"
                          required=""
                          onChange={(e) => {
                            setemail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="input-group">
                      <div className="single-input">
                        <label htmlFor="phone">
                          {" "}
                          {lang.value === "EN"
                            ? `Phone Number`
                            : lang.value === "FR"
                            ? `Numéro de téléphone`
                            : `رقم التليفون`}{" "}
                        </label>
                        <input
                          type="number"
                          id="phone"
                          name="phone"
                          placeholder="Your Number"
                          required=""
                          min={1}
                          onChange={(e) => {
                            setphone(e.target.value);
                          }}
                        />
                      </div>
                      <div className="single-input">
                        <label htmlFor="skype">
                          {" "}
                          {lang.value === "EN"
                            ? `Skype Address`
                            : lang.value === "FR"
                            ? `Adresse Skype`
                            : `عنوان سكايب`}{" "}
                        </label>
                        <input
                          type="text"
                          id="skype"
                          name="skype"
                          placeholder="Your skype"
                          onChange={(e) => {
                            setskype(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="textarea">
                      <label htmlFor="msg">
                        {" "}
                        {lang.value === "EN"
                          ? `Message`
                          : lang.value === "FR"
                          ? `Message`
                          : `رسالة`}
                      </label>
                      <textarea
                        id="msg"
                        name="msg"
                        placeholder="Your Review"
                        required=""
                        defaultValue={""}
                        onChange={(e) => {
                          setmessage(e.target.value);
                        }}
                      />
                    </div>
                    <div className="submit-btn">
                      <button
                        className="main-btn"
                        onClick={(e) => addContact(e)}
                      >
                        {lang.value === "EN"
                          ? `Send Message`
                          : lang.value === "FR"
                          ? `Envoyer un message`
                          : `إرسال رسالة`}
                      </button>
                    </div>
                  </form>
                  <div className="row">
                    <div className="col-12">
                      <div
                        className="alert alert-success contact__msg"
                        style={{ display: "none" }}
                        role="alert"
                      >
                        Your message was sent successfully.
                      </div>
                    </div>
                  </div>
                  {/* end message */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* contact us end */}
      {/* map */}
      <div className="contact__map">
        <div id="map">
          {/* if you want to use leaflet map just remove <iframe></iframe> */}
          <iframe
            className="google__map w-100"
            src="https://maps.google.com/maps?q=QFC%20tower%2C%20Doha-Qatar&t=m&z=11&output=embed&iwloc=near"
          ></iframe>
        </div>
      </div>
      {/* map end */}

      {/* footer */}
      <footer className="footer pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Contact Us</h5>
                <div className="footer__widget--contact">
                  <ul>
                    <li>
                      <i className="fa-sharp fa-solid fa-phone" />
                      <a href="tel:+8801755202096">
                        {isEmpty(data?.Configurations)
                          ? "phone"
                          : data?.Configurations?.phone}
                      </a>
                    </li>
                    <li>
                      <i className="fa-regular fa-envelope" />
                      <a href="mailto:contact@hma.org">
                        {" "}
                        {isEmpty(data?.Configurations)
                          ? "email"
                          : data?.Configurations?.email}
                      </a>
                    </li>
                  </ul>
                  {/* <div className="footer__social">
                    <a href="#">
                      <i className="fa-brands fa-facebook-f" />
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-twitter" />
                    </a>
                    <a href="#">
                      <i className="fa-brands fa-instagram" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>
                  {lang.value === "EN"
                    ? `Links`
                    : lang.value === "FR"
                    ? `Links`
                    : `الروابط`}{" "}
                </h5>
                <div className="footer__widget--link">
                  <ul>
                    <li>
                      <a href="#homeid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `Home`
                          : lang.value === "FR"
                          ? `Accueil`
                          : `الصفحة الرئيسية`}
                      </a>
                    </li>
                    <li>
                      <a href="#aboutid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `About`
                          : lang.value === "FR"
                          ? `À propos de nous`
                          : `معلومات عنا`}
                      </a>
                    </li>
                    <li>
                      <a href="#projectsid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `Portfolio`
                          : lang.value === "FR"
                          ? `Portefeuille`
                          : `المشاريع`}
                      </a>
                    </li>
                    <li>
                      <a href="#Servicesid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `Services`
                          : lang.value === "FR"
                          ? `Services`
                          : `خدمات`}
                      </a>
                    </li>
                    {/* <li>
                      <a href="#teamid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `Team`
                          : lang.value === "FR"
                          ? `Équipe`
                          : `فريق`}
                      </a>
                    </li> */}
                    <li>
                      <a href="#contactid" onClick={(e) => setmenu(false)}>
                        {lang.value === "EN"
                          ? `Contact`
                          : lang.value === "FR"
                          ? `Contact`
                          : `اتصال`}
                      </a>
                    </li>

                    <li>
                      <a
                        href="#"
                        onClick={(e) => {
                          navigate("/events");
                          setmenu(false);
                        }}
                      >
                        {lang.value === "EN"
                          ? `HMA Events`
                          : lang.value === "FR"
                          ? `Événements HMA`
                          : `فعاليات HMA`}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>Resources</h5>
                <div className="footer__widget--resource">
                  <ul>
                    <li>
                      <a href="#">Help Center</a>
                    </li>
                    <li>
                      <a href="#">User Guide</a>
                    </li>
                    <li>
                      <a href="#">Realease</a>
                    </li>
                    <li>
                      <a href="#">Video tutorial </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="footer__widget">
                <h5>
                  {lang.value === "EN"
                    ? `Newsletter`
                    : lang.value === "FR"
                    ? `Bulletin`
                    : `نشرة`}{" "}
                </h5>
                <div className="footer__widget--newsletter">
                  <p>
                    {lang.value === "EN"
                      ? `   If your business hosts events or exhibitions, creative
                    services can assist with event planning, booth design.`
                      : lang.value === "FR"
                      ? `Si votre entreprise organise des événements ou des expositions, des services créatifs peuvent vous aider à planifier vos événements et à concevoir des stands.`
                      : `إذا كان عملك يستضيف فعاليات أو معارض، كن مبدعًا
 يمكن أن تساعد الخدمات في تخطيط الأحداث وتصميم الكشك.`}
                  </p>
                  <form action="#" className="newsletter-box">
                    <input type="email" placeholder="" />
                    <button type="submit">
                      {lang.value === "EN"
                        ? `Submit`
                        : lang.value === "FR"
                        ? `Soumettre.`
                        : `لإرسال`}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* footer end */}
      {/* footer credit */}
      <div className="footer-credit">
        <div className="container">
          <div className="footer-credit--img">
            <a onClick={() => navigate("/")}>
              <img src={Logo} alt="footer logo" />
            </a>
          </div>
          <div className="footer-credit__wrapper mt-25">
            <div className="copy-right">
              {lang.value === "EN"
                ? `Copyright`
                : lang.value === "FR"
                ? `Copyright`
                : `حقوق الطبع والنشر`}{" "}
              © {new Date().getFullYear()} - <a href="#"> HMA Techs</a>
              {lang.value === "EN"
                ? `All Rights Reserved`
                : lang.value === "FR"
                ? `Tous droits réservés `
                : ` جميع الحقوق محفوظة`}
            </div>
          </div>
        </div>
      </div>
      {/* footer credit end */}

      <Modal
        isOpen={modalopen}
        toggle={() => setmodalopen(!modalopen)}
        className="my-modal"
        centered
        size="lg"
      >
        <ModalBody>
          <h4>
            {" "}
            {lang.value === "EN"
              ? item?.title
              : lang.value === "FR"
              ? item?.titleFR
              : item?.titleAR}
          </h4>

          <p>
            {lang.value === "EN"
              ? item?.description
              : lang.value === "FR"
              ? item?.descriptionFR
              : item?.descriptionAR}
          </p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Home;
